<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Notfallnummern">
      <template #actions>
        <region-chooser
          v-if="$store.getters['auth/isSuperAdmin']"
          @region:chosen="loadNumbers"
        ></region-chooser>
        <el-button type="primary" icon="fal fa-plus" @click="newNumber">
          Neue Nummer
        </el-button>
      </template>
      <el-table
        v-loading="loading"
        :data="numbers"
        stripe
        :default-sort="{ prop: 'name', order: 'ascending' }"
      >
        <el-table-column prop="name" label="Name" sortable></el-table-column>
        <el-table-column prop="number" label="Telefonnummer"></el-table-column>
        <el-table-column label="Aktionen" width="260">
          <template #default="scope">
            <el-button
              icon="fal fa-edit"
              size="mini"
              @click="handleEdit(scope.row)"
            >
              Bearbeiten
            </el-button>
            <delete-button
              v-slot="slotProps"
              :on-delete="deleteNumber"
              subject="Nummer"
            >
              <el-button
                size="mini"
                icon="fal fa-trash-alt"
                type="danger"
                @click="slotProps.handleDelete(scope.row)"
              >
                Löschen
              </el-button>
            </delete-button>
          </template>
        </el-table-column>
      </el-table>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionChooser from "@/components/RegionChooser"
import DeleteButton from "@/components/DeleteButton.js"
import EmergencyNumbersRepository from "@/repositories/emergency_numbers_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Notfallnummern"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionChooser,
    DeleteButton
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: false,
      numbers: []
    }
  },
  async created() {
    this.loadNumbers()
  },
  methods: {
    async loadNumbers() {
      this.loading = true

      try {
        this.numbers = await EmergencyNumbersRepository.getAll()
        this.loading = false
      } catch (error) {
        this.loading = false
        this.handleApiError(error)
      }
    },
    handleEdit(number) {
      this.$router.push({
        name: "EditEmergencyNumberPage",
        params: { id: number.id }
      })
    },
    async deleteNumber(number) {
      this.numbers.splice(this.numbers.indexOf(number), 1)
      await EmergencyNumbersRepository.destroy(number.id)
    },
    newNumber() {
      this.$router.push({ name: "NewEmergencyNumberPage" })
    }
  }
}
</script>
