import store from "@/store"
import Repository from "@/repositories/api.js"
const resource = "/admin/emergency_numbers"

export default {
  async getAll() {
    let response = await Repository.get(resource, {
      params: { region_id: store.state.auth.chosenRegion }
    })
    return response.data.emergency_numbers
  },
  async get(numberID) {
    let response = await Repository.get(`${resource}/${numberID}`)
    return response.data.emergency_number
  },
  async create(numberData) {
    let response = await Repository.post(`${resource}`, {
      region_id: store.state.auth.chosenRegion,
      emergency_number: numberData
    })
    return response.data
  },
  async update(numberID, numberData) {
    let response = await Repository.patch(`${resource}/${numberID}`, {
      emergency_number: numberData
    })
    return response.data
  },
  async destroy(numberID) {
    let response = await Repository.delete(`${resource}/${numberID}`)
    return response.data
  }
}
